import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-icons/vsc';
import {IconContext} from 'react-icons';
const Icon = ( {name} ) => {
    const IconComponent = Icons[name];
    if (!IconComponent) {
        return (
            <IconContext.Provider value={{ style: {fontSize: '90px', color: "rgb(255, 255, 255)"}}}>
                <div>
                    <Icons.VscAccount/>
                </div>
            </IconContext.Provider>
        )    
    }
    return(
    <IconContext.Provider value={{ style: {fontSize: '90px', color: "rgb(255,255,255"}}}>
    <div>
        <IconComponent/>
    </div>
</IconContext.Provider>) 
};

Icon.propTypes = {
  fontSize: PropTypes.string,
  color: PropTypes.string,
};

Icon.defaultProps = {
  fontSize: '20px',
  color: 'red',
};

export default Icon;